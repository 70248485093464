@import '../variables'

.author-section
  .slick-arrow
    top: 32%

.author-section
  .slick-prev
    +tablet
      left: 0
  .slick-next
    +tablet
      right: 0
