@use "sass:math"
@import '../variables'
@import '../mixins/hollow-button'
@import '../mixins/block-button'
@import '../mixins/responsive'
@import '../mixins/clearfix'

$breakpoint-huge: $fourteen-columns-wide

// Add a gradient to images so we can overlay white text on it.
$home-hero-floor-fade-light: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6))
// Add a heavier gradient to light images so we can overlay white text on it.
$home-hero-floor-fade-dark:  linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9))

.home-hero
  @include pie-clearfix

  background-color: #151515

  // Section sizes to suit the 3 column layout used on all desktop breakpoints.
  @media (min-width: $breakpoint-desktop)
    .topic-code
      height: 600px

    .topic-design
      height: 400px
      background-position: 50% 0

    .topic-webdesign
      height: 200px

    .topic-business, .topic-photography
      height: 150px

    .subscribe
      height: 300px

  // 14 column grid split into 3 (5/4/5)
  @media (min-width: $breakpoint-huge)
    .home-hero__column
      float: left
      width: $five-columns-wide
      &:nth-child(2)
        width: $four-columns-wide

  // 12 column grid split into 3 (4/4/4)
  @media (min-width: $breakpoint-wide) and (max-width: $breakpoint-huge - 1)
    .home-hero__column
      float: left
      width: $four-columns-wide + math.div((2 * $gutter-width), 3)

  // 9 column grid split into 3 (3/3/3)
  @media (min-width: $breakpoint-desktop) and (max-width: $breakpoint-wide - 1)
    .home-hero__column
      float: left
      width: $three-columns-wide + math.div((2 * $gutter-width), 3)

  // 6 column grid split into 2 (3/3)
  @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop - 1)
    .home-hero__column
      &:first-child
        float: none
        width: 100%

      float: left
      width: $three-columns-wide + math.div((2 * $gutter-width), 3)

    // Section sizes to suit the 2 column layout.
    .topic-code
      height: 300px
      background-position: 0 60%

    .topic-design
      height: 400px
      background-position: 50% 0

    .topic-webdesign
      height: 200px

    .topic-business, .topic-photography
      height: 150px

    .subscribe
      height: 300px

  // 1 column stacked
  @media (max-width: $breakpoint-tablet - 1)
    max-width: $breakpoint-tablet
    width: 100%

    // Section sizes to suite a 1 column stacked layout.
    .topic-code, .topic-design, .topic-webdesign
      height: 220px

    .topic-business, .topic-photography
      height: 150px

    .subscribe
      height: 300px

    .topic-code
      background-position: 0 50%



  .home-hero__name
    font-size: 30px
    font-weight: bold
    padding-bottom: 15px

    // Undo any styles applied by heading tags
    margin: 0
    line-height: 36px

  .home-hero__description, .home-hero__description--dark, .home-hero__description--small
    font-size: 18px
    line-height: 1.4em

    // Undo any styles applied by heading tags
    font-weight: normal
    margin: 0

  .home-hero__description, .home-hero__description--small
    color: $white

  .home-hero__description--dark
    color: #000000

  .home-hero__description--small
    font-size: 16px

  .home-hero__cta
    padding-top: 15px

  .home-hero__cta-button
    @include block-button($white, $teal800)
    max-width: 350px

  .home-hero__background-image
    background-size: cover
    background-repeat: no-repeat

    transition: opacity .25s ease-in-out

    &:hover
      opacity: 0.9

  .home-hero__background, .home-hero__background-image
    &:hover
      .home-hero__description, .home-hero__description--dark
        text-decoration: underline

    +topic-colors
      .home-hero__name
        color: $topic-color

    position: relative

  .home-hero__content, .home-hero__content--center
    position: absolute
    max-width: 400px
    padding: 0 30px

  .home-hero__content
    bottom: 30px

  .home-hero__content--center
    top: 50%
    transform: translateY(-50%)

  .topic-code
    background-image: $home-hero-floor-fade-light, url('images/home/heros/code.jpg')
    +mobile
      background-image: $home-hero-floor-fade-light, url('images/home/heros/code_mobile.jpg')

  .topic-design
    background-image: $home-hero-floor-fade-dark, url('images/home/heros/design.jpg')
    +mobile
      background-image: $home-hero-floor-fade-light, url('images/home/heros/design_mobile.jpg')

  .topic-webdesign
    background-image: $home-hero-floor-fade-dark, url('images/home/heros/webdesign.jpg')
    +mobile
      background-image: $home-hero-floor-fade-light, url('images/home/heros/webdesign_mobile.jpg')

  .topic-business
    background-image: $home-hero-floor-fade-dark, url('images/home/heros/business.jpg')
    +mobile
      background-image: $home-hero-floor-fade-light, url('images/home/heros/business_mobile.jpg')

  .topic-photography
    background-size: contain
    background-image: $home-hero-floor-fade-dark, url('images/home/heros/photography.png')
    +mobile
      background-image: $home-hero-floor-fade-light, url('images/home/heros/photography.png')

    .home-hero__name
      color: #FFFFFF !important

  .subscribe
    background-color: $teal800

    .home-hero__name
      color: $white
