@use "sass:math"
@import '../mixins/responsive'
@import '../variables'

$breakpoint-ultrawide: $fourteen-columns-wide
$hero-gutter-width: math.div((2 * $gutter-width), 3)

.home
  .content-title--section-switcher-item-link__active
    border-color: $envatoGreen

  +tablet
    .content-title--section-switcher-item
      font-size: 18px

  +mobile
    .content-title--section-switcher-item
      font-size: 18px
      display: block
      text-align: center

    .content-title--section-switcher-item-link
      padding: 15px 25px 15px

  .popular-content
    @include container
