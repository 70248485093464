@import '../variables'
@import '../mixins/block-button'
@import '../mixins/three-column-card'
@import '../mixins/hollow-button'

.elements-highlight
  background: $grey25
  width: 100%
  padding: 30px 0
  text-align: center

.elements-highlight__heading
  margin: auto
  max-width: 80%
  +wide
    max-width: 850px

.elements-highlight__intro
  text-transform: uppercase
  color: $grey300
  margin-bottom: 10px
  font-weight: bold
  font-size: 18px

.elements-highlight__title
  font-size: 30px
  line-height: 1.4
  padding: 10px 0 30px 0
  +mobile-and-tablet
    font-size: 21px

.elements-highlight__cards
  @include container
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  align-content: space-between
  +desktop
    max-width: $six-columns-wide
  &:after
    display: none

.elements-highlight__card
  width: $three-columns-wide
  margin-bottom: 30px
  +mobile
    margin-left: auto
    margin-right: auto

  background: $white
  border: solid 1px $borderColor
  border-radius: 4px
  text-align: left
  a
    color: $bodyTextColor

.elements-highlight__card-image
  width: 100%
  height: 170px

.elements-highlight__card-content
  padding: 15px
  margin-top: -8px

.elements-highlight__card-title
  font-weight: bold

.elements-highlight__card-description
  font-size: 14px
